import Splash1 from '../../assets/backgrounds/login-brand-image-1-white.jpg';
import Splash2 from '../../assets/backgrounds/login-brand-image-2-white.jpg';
import Splash3 from '../../assets/backgrounds/login-brand-image-3-white.jpg';
import Splash4 from '../../assets/backgrounds/login-brand-image-4-white.jpg';
import Splash5 from '../../assets/backgrounds/login-brand-image-6-white.jpg';
import Splash6 from '../../assets/backgrounds/login-brand-image-7-white.jpg';

const backgrounds = [Splash1, Splash2, Splash3, Splash4, Splash5, Splash6];

const key = 'BACKGROUND_IMAGE';

// Function to get the background image for the session
export const getStyledBackgroundImage = () => {
  var backgroundStyle = {
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover'
  };

  // Check if the background has already been set in sessionStorage
  let selectedBackground = sessionStorage.getItem(key);

  // If no background has been set, randomly select one and store it
  if (!selectedBackground) {
    const randomIndex = Math.floor(Math.random() * backgrounds.length);
    selectedBackground = backgrounds[randomIndex].toString();
    sessionStorage.setItem(key, selectedBackground); // Store the selected background
  }

  // Return the selected background style
  return {
    ...backgroundStyle,
    backgroundImage: `url(${selectedBackground})`
  };
};
