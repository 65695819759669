import { Button } from '../../components/button/button';
import { ButtonSize } from '../../core/enums';
import { ButtonVariant } from '../../core/enums';
import { Checkbox } from '@chakra-ui/react';
import { Flex } from '@chakra-ui/react';
import { Select } from '../../components/select/select';

import { push } from 'connected-react-router';
import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { chooseServiceLine } from '../../actions/service-lines';
import { saveUserPreference } from '../../actions/user-preferences';
import { commonEnums } from '@codametrix/ui-common';
import { Action } from 'redux';
const { UserPreferenceKey } = commonEnums;
const ServiceLines = () => {
  const { context } = useSelector((state: CMx.ApplicationState) => state.ui);
  const { activeContext } = context;

  const { loading, activeUser, homepagePreference } = useSelector(
    (state: CMx.ApplicationState) => state.ui
  );

  const dispatch = useDispatch();
  useEffect(() => {
    if (activeContext?.serviceLines === null) {
      dispatch(push('/'));
    }
  }, [dispatch, activeContext]);

  const [selectedServiceLine, selectServiceLine] = useState<number | null>(
    null
  );
  const [selectedServiceLineName, selectServiceLineName] = useState<string>('');
  const [isDefault, setIsDefault] = useState<boolean>(false);

  const history = useHistory();

  const handleDefaultCheck = (e: SyntheticEvent<HTMLInputElement>) => {
    setIsDefault(!isDefault);
  };

  const handleSave = (e?: React.MouseEvent<HTMLElement>) => {
    if (e) {
      e.preventDefault();
      if (selectedServiceLine !== null) {
        const serviceLine = activeContext?.serviceLines.find(
          serviceLine => serviceLine.id === selectedServiceLine
        );
        if (serviceLine) {
          dispatch(
            (chooseServiceLine({
              serviceLine,
              context,
              homepagePreference
            }) as any) as Action
          );
        }
        if (isDefault) {
          dispatch(
            (saveUserPreference({
              userId: activeUser.id,
              value: selectedServiceLine.toString(),
              keyname: UserPreferenceKey.DEFAULT_USER_SERVICE_LINE
            }) as any) as Action
          );
        }
      }
    }
  };

  const handleServiceLineChoice = (event: React.MouseEvent<HTMLElement>) => {
    const target = event.target as HTMLInputElement;
    const { value, textContent } = target;
    selectServiceLine(+value);
    selectServiceLineName(textContent!);
  };

  const handleBackSelection = (e?: React.MouseEvent<HTMLElement>) => {
    if (e) {
      e.preventDefault();
      history.goBack();
    }
  };

  const convertServiceLines = (items: CMxAPI.ServiceLine[]) =>
    items.map((item: CMxAPI.ServiceLine) => ({
      value: item.id,
      label: item.name
    }));

  const activeOrgDisplay =
    context.activeContext?.displayName &&
    context.activeContext?.displayName !== ''
      ? context.activeContext?.displayName
      : context.activeContext?.shortDisplayName &&
        context.activeContext?.shortDisplayName !== ''
      ? context.activeContext?.shortDisplayName
      : context.activeContext?.organizationCode &&
        context.activeContext?.organizationCode !== ''
      ? context.activeContext?.organizationCode
      : '';

  return (
    <div className="service-line-choice-form form-group">
      <div className="form-row">
        <label className="form-label">
          Which service line at {activeOrgDisplay}?
        </label>
        <Select
          placeholder="Choose your service line"
          items={convertServiceLines(activeContext?.serviceLines!)}
          value={selectedServiceLineName}
          onChange={handleServiceLineChoice}
        />
      </div>
      <div className="checkbox-container">
        <Checkbox onChange={handleDefaultCheck} />
        <i className="form-icon"></i> Default service line
      </div>
      <Flex className="form-buttons">
        <Button
          size={ButtonSize.MD}
          label="Back"
          variant={ButtonVariant.LINKS}
          dataTestId="back"
          onClick={handleBackSelection}
        />
        <Button
          size={ButtonSize.MD}
          label={'Continue'}
          variant={ButtonVariant.PRIMARY}
          disabled={!selectedServiceLine || loading}
          name="Continue"
          dataTestId="continue"
          onClick={handleSave}
        />
      </Flex>
    </div>
  );
};

export default ServiceLines;
