import { Flex, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  saveDefaults,
  saveFormData,
  toggleIsOpen,
  updateFormDefinition
} from '../../actions/account-settings';
import { listNormalTime, saveNormalTime } from '../../actions/normal-time';
import { Button } from '../../components/button/button';
import { ModalComponent } from '../../components/Modal/modal';
import { MenuItemType, Select } from '../../components/select/select';
import { styles } from './account-settings.styles';
import {
  defaultHomepage as homepageField,
  defaultOrg as orgField,
  defaultServiceLine as serviceLineField
} from '../../reducers/state/account-settings';
import theme from '../../theme';
import { WorkingHours } from '../time-entry/working-hours';
import { Action } from 'redux';
import { api } from '../../core/net';
import {
  getDefaultOrg,
  getDefaultServiceLine
} from '../../core/preferences/preferences';

//region FormDefinition Rule Evaluation
const evaluateSingleRule = (
  rule: CMxCommonApp.SingleRule,
  fieldGroups: CMxCommonApp.FieldGroup[],
  formValues: any
): boolean => {
  const fieldValue = formValues[rule.field];

  switch (rule.operator) {
    case 'eq':
      // For some reason the rule with int as a value was not correctly comparing to fieldValue int with ===
      // @ts-ignore
      // eslint-disable-next-line eqeqeq
      return fieldValue == rule.value;
    case 'neq':
      // For some reason the rule with int as a value was not correctly comparing to fieldValue int with ===
      // @ts-ignore
      // eslint-disable-next-line eqeqeq
      return fieldValue != rule.value;
    default:
      return false;
  }
};

const evaluateLogicalRule = (
  rule: CMxCommonApp.LogicalRule,
  fieldGroups: CMxCommonApp.FieldGroup[],
  formValues: any
): boolean => {
  if (rule.rules.length === 0) return true;

  return rule.operator === 'AND'
    ? rule.rules.every(r => evaluateRule(r, fieldGroups, formValues))
    : rule.rules.some(r => evaluateRule(r, fieldGroups, formValues));
};

const evaluateRule = (
  rule: CMxCommonApp.SingleRule | CMxCommonApp.LogicalRule,
  fieldGroups: CMxCommonApp.FieldGroup[],
  formValues: any
): boolean => {
  if (
    'operator' in rule &&
    ('AND' === rule.operator || 'OR' === rule.operator)
  ) {
    return evaluateLogicalRule(rule, fieldGroups, formValues);
  }
  return evaluateSingleRule(
    rule as CMxCommonApp.SingleRule,
    fieldGroups,
    formValues
  );
};

export const runDefinitionRules = (
  currentField: CMxCommonApp.FieldDefinition,
  fieldGroups?: CMxCommonApp.FieldGroup[],
  formValues?: any
): boolean => {
  if (!fieldGroups || fieldGroups.length === 0) return true;

  if (currentField.rules) {
    return evaluateRule(currentField.rules, fieldGroups, formValues);
  }

  return true;
};
//endregion

export const AccountSettings = () => {
  const classes = styles();

  const {
    accountDefaults: { formDefinition, formObject }
  } = useSelector((state: CMx.ApplicationState) => state.accountSettings);
  const { context } = useSelector((state: CMx.ApplicationState) => state.ui);
  const { savedHours } = useSelector(
    (state: CMx.ApplicationState) => state.workingHours
  );

  const { user } = context;
  const dispatch = useDispatch();

  const activeOrg = context.activeContext;
  const activeOrgName = activeOrg?.organizationName || 'Organization';

  const resources = context.activeContext?.resources ?? [];
  const timeEntryResource = new Set(['protected_time']);
  const timeEntry = resources.some((resource: any) =>
    timeEntryResource.has(resource.resourceName)
  );

  const [hours, setHours] = useState<{ [key: string]: number }>(savedHours);
  const [activeDays, setActiveDays] = useState<{ [key: string]: boolean }>({
    Sunday: false,
    Monday: false,
    Tuesday: false,
    Wednesday: false,
    Thursday: false,
    Friday: false,
    Saturday: false
  });

  //region Helper Functions
  const handlePropChange = (prop: string, value: string) => {
    const selectedOrg = context.activeContext;

    if (prop === orgField.name) {
      dispatch(
        saveFormData({
          defaultOrg: parseInt(value)
        })
      );
      dispatch(updateFormDefinition(context));
    }

    if (prop === serviceLineField.name) {
      dispatch(
        saveFormData({
          defaultServiceLine: parseInt(value)
        })
      );
    }

    if (prop === homepageField.name && selectedOrg) {
      const newHomepage = {
        path: value,
        tenantId: selectedOrg.tenantId
      };

      dispatch(
        saveFormData({
          defaultHomepage: newHomepage
        })
      );
    }

    if (prop === homepageField.name && !selectedOrg) {
      const newHomepage = {
        path: value,
        tenantId: ''
      };

      dispatch(
        saveFormData({
          defaultOrg: undefined,
          defaultServiceLine: undefined,
          defaultHomepage: newHomepage
        })
      );
    }
  };

  const handleClose = () => {
    dispatch(toggleIsOpen(false));
  };

  const handleSaveAccountDefaults = () => {
    if (user.id) {
      const accountDefaultsPayload: CMx.SaveAccountDefaultPayload = {
        userId: user.id,
        defaultOrg: formObject.defaultOrg,
        defaultServiceLine: formObject.defaultServiceLine
      };
      dispatch((saveDefaults(accountDefaultsPayload) as any) as Action);
    }
  };

  const handleSaveOrgDefaults = () => {
    if (user.id && activeOrg) {
      const orgDefaultsPayload: CMx.SaveAccountDefaultPayload = {
        userId: user.id,
        defaultHomepage: formObject.defaultHomepage
      };
      dispatch((saveDefaults(orgDefaultsPayload) as any) as Action);
    }
  };

  const handleSaveWorkingHours = () => {
    if (timeEntry) {
      let activeHours: { [key: string]: number } = {};
      Object.keys(hours).forEach(day => {
        if (activeDays[day] || (hours[day] >= 0 && hours[day] <= 24)) {
          activeHours[day] = hours[day];
        } else {
          activeHours[day] = 0;
        }
      });
      dispatch(
        (saveNormalTime({ hours: activeHours, user, context }) as any) as Action
      );
    }
  };

  const handleSave = () => {
    handleSaveAccountDefaults();
    if (activeOrg) {
      handleSaveOrgDefaults();
    }
    if (timeEntry) {
      handleSaveWorkingHours();
    }
  };

  const onOptionChange = (event: React.MouseEvent<HTMLElement>) => {
    const target = event.target as HTMLInputElement;
    const { name, value } = target;
    handlePropChange(name, value);
  };

  const optionConverter = (field: CMxCommonApp.FieldDefinition) => {
    const { options, labelField, valueField } = field;
    let opts: MenuItemType[] = [];
    options?.map((item: any) => {
      opts?.push({ label: item[labelField], value: item[valueField] });
      return {};
    });
    return opts;
  };

  const getSelectedValue = (
    field: CMxCommonApp.FieldDefinition,
    key: string
  ) => {
    const options = field?.options;
    if (!options) {
      return '';
    }

    if (options.length === 1) {
      return options[0][field.labelField];
    }

    const res: any = options?.filter(
      (item: any) => String(item[field.valueField]) === key
    );
    return res && res?.[0]?.[field.labelField];
  };

  const getFieldValue = (field: any): string => {
    switch (field.key) {
      case 'defaultOrg':
        return formObject.defaultOrg?.toString() || '';
      case 'defaultServiceLine':
        return formObject.defaultServiceLine?.toString() || '';
      case 'defaultHomepage':
        return formObject.defaultHomepage?.path || '';
      default:
        return '';
    }
  };
  //endregion

  //region Lifecycle
  useEffect(() => {
    const initializeComponent = async () => {
      // Update form definition first to ensure we have the correct structure
      dispatch(updateFormDefinition(context));

      // Load working hours if timeEntry is enabled
      if (timeEntry && user.userId && context.activeContext?.tenantId) {
        dispatch(
          (listNormalTime({
            dateToday: new Date(),
            userId: user.userId,
            tenantId: context.activeContext?.tenantId
          }) as any) as Action
        );
      }

      // Fetch user preferences
      try {
        if (context.user?.id) {
          const userDefaults = await api<CMxAPI.Preference[]>({
            endpoint: `/userpreferences/${context.user.id}/v1`
          });

          // Extract preference values
          const defaultOrgId = getDefaultOrg(userDefaults) ?? -1;
          const defaultServiceLine = getDefaultServiceLine(userDefaults) ?? -1;

          // Set default homepage
          const defaultHomepage = formObject.defaultHomepage || {
            path: 'None',
            tenantId: context.activeContext?.tenantId || ''
          };

          dispatch(
            saveFormData({
              defaultOrg: defaultOrgId,
              defaultServiceLine: defaultServiceLine,
              defaultHomepage
            })
          );
        }
      } catch (error) {
        console.error('Error initializing account settings:', error);

        // Set fallback values if preferences fetch fails
        const defaultOrgId = context.activeContext?.organizationId || -1;
        const defaultServiceLineId = -1;

        dispatch(
          saveFormData({
            defaultOrg: defaultOrgId,
            defaultServiceLine: defaultServiceLineId,
            defaultHomepage: formObject.defaultHomepage || {
              path: 'None',
              tenantId: context.activeContext?.tenantId || ''
            }
          })
        );
      }
    };

    initializeComponent();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //endregion

  //region Components
  const renderSaveButton = (label: string, onClick: () => void) => (
    <Button
      dataTestId={`save${label.replace(/\s+/g, '')}Btn`}
      label={`Save ${label}`}
      onClick={onClick}
    />
  );

  const renderFormGroups = () => {
    if (!formDefinition.fieldGroups) return null;
    let fieldGroupsToRender = [...formDefinition.fieldGroups];

    return fieldGroupsToRender.map((item: any, index: number) => {
      const groupLabel =
        index === 1 && item.label !== 'Account Defaults'
          ? `${activeOrgName} Defaults`
          : item.label || '';

      return (
        <fieldset style={classes.fieldset} key={index}>
          <legend style={classes.legend}>{groupLabel}</legend>
          <Flex
            flexDirection={'column'}
            rowGap={5}
            padding={
              theme.space[4] +
              ' ' +
              theme.space[16] +
              ' ' +
              theme.space[16] +
              ' ' +
              theme.space[16]
            }>
            {item.fields.map((field: any) => {
              return (
                <Flex
                  flexDirection={'row'}
                  columnGap={5}
                  width={'66%'}
                  alignItems={'center'}
                  key={field.name}>
                  <Text role="textbox" width={'25%'} paddingBottom={5}>
                    {field.label}
                  </Text>
                  <Flex flexDirection={'column'} width={'100%'}>
                    <div className="form-group">
                      <Select
                        items={optionConverter(field)}
                        name={field.name}
                        dataTestId={field.name}
                        value={getSelectedValue(field, getFieldValue(field))}
                        onChange={onOptionChange}
                      />

                      <Text role="textbox" sx={classes.label}>
                        {field.helpText}
                      </Text>
                    </div>
                  </Flex>
                </Flex>
              );
            })}
            <Flex justifyContent="flex-end" marginTop={3}>
              {item.label === 'Account Defaults' &&
                renderSaveButton('Account Defaults', handleSaveAccountDefaults)}
              {item.label !== 'Account Defaults' &&
                index === 1 &&
                renderSaveButton(
                  `${activeOrgName} Defaults`,
                  handleSaveOrgDefaults
                )}
            </Flex>
          </Flex>
        </fieldset>
      );
    });
  };
  //endregion

  return (
    <ModalComponent
      size={'xl'}
      width={'52rem'}
      isOpen={true}
      handleClose={handleClose}
      showCloseIcon={true}
      modalHeader={<div>Account Settings</div>}
      modalContent={
        <Flex flexDirection={'column'}>
          {renderFormGroups()}
          {timeEntry && (
            <fieldset style={classes.fieldset}>
              <legend style={classes.legend}>Working Hours</legend>
              <WorkingHours
                hours={hours}
                activeDays={activeDays}
                setHours={setHours}
                setActiveDays={setActiveDays}
              />
              <Flex justifyContent="flex-end" margin={5}>
                {renderSaveButton('Working Hours', handleSaveWorkingHours)}
              </Flex>
            </fieldset>
          )}
        </Flex>
      }
      modalFooter={
        <>
          <Button
            dataTestId="saveAllBtn"
            label={'Save All'}
            onClick={handleSave}></Button>
        </>
      }
    />
  );
};
