import { buildRoleSet } from '../../reducers/users/roles';
import {
  ActionDescription,
  AnalyticsProvider,
  ViewDescription,
  ViewMatcher
} from './analytics-types';
import { _noOpProvider } from './base-provider';

let dataDogUserId = '';

type DDRUM = {
  startView: (view: string | ViewDescription) => void;
  setUser: (user: { name: string; id: number | null; email: string }) => void;
  setUserProperty: (key: string, value: any) => void;
  reset: () => void;
  addAction: (action: ActionDescription, obj: any) => void;
};

const stubbedRUM: DDRUM = {
  startView: () => {
    console.log('DataDog Rum not defined: startView not initialized');
  },
  setUser: () => {
    console.log('DataDog Rum not defined: setUser not initialized');
  },
  setUserProperty: () => {
    console.log('DataDog Rum not defined: setUserProperty not initialized');
  },
  reset: () => {
    console.log('DataDog Rum not defined: reset not initialized');
  },
  addAction: () => {
    console.log('DataDog Rum not defined: addAction not initialized');
  }
};

export const getRUM = (): DDRUM => {
  return ((window as any).DD_RUM as DDRUM) || stubbedRUM;
};

export const dataDogFactory = (
  dataDogViewMatchers: ViewMatcher[]
): AnalyticsProvider => {
  const getDataDogViewDescription = (
    url: string
  ): ViewDescription | string | null => {
    for (const { pattern, getDescription } of dataDogViewMatchers) {
      const matches = pattern.exec(url);
      if (matches) {
        return getDescription(matches);
      }
    }
    return null;
  };

  return {
    ..._noOpProvider,
    handleLocationChange: (location: Location) => {
      const viewDescriptor = getDataDogViewDescription(location.pathname);
      if (viewDescriptor) {
        getRUM().startView(viewDescriptor);
      }
    },
    setContext: (context: CMx.Context) => {
      const activeRolesforDD = buildRoleSet(context);
      const activeHSforDD = context.activeContext?.healthSystemCode;
      const activeOrgforDD = context.activeContext?.organizationCode;
      const activeHSTypeforDD = context.activeContext?.organizationType;
      const rum = getRUM();
      rum.setUserProperty('roles', activeRolesforDD);
      rum.setUserProperty('healthSystem', activeHSforDD);
      rum.setUserProperty('organization', activeOrgforDD);
      rum.setUserProperty('organizationType', activeHSTypeforDD);
    },
    setUser: (user: CMxAPI.User) => {
      if (dataDogUserId === user.userId) {
        return;
      }
      dataDogUserId = user.userId;
      getRUM().setUser({
        id: user.id,
        name: user.firstName + ' ' + user.lastName,
        email: user.username
      });
    },
    setServiceLine: (serviceLine: CMxAPI.ServiceLine) => {
      getRUM().setUserProperty('serviceLine', serviceLine?.name ?? '');
    },
    trackView: (viewName: string | ViewDescription) => {
      getRUM().startView(viewName);
    },
    trackAction: (
      action: ActionDescription,
      data?: Record<string, unknown>
    ) => {
      // Implementation for tracking actions
      getRUM().addAction(action, data);
    },
    reset: () => {
      dataDogUserId = '';
    }
  };
};
