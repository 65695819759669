import {
  ActionDescription,
  AnalyticsProvider,
  ViewDescription
} from './analytics-types';

export enum AnalyticsProviders {
  DATADOG = 'DATADOG',
  MIXPANEL = 'MIXPANEL'
}

/**
 * Takes n number of providers and executes the calls on each
 * as if it is a single provider.
 */
export const analyticsFactory = (
  providers: AnalyticsProvider[]
): AnalyticsProvider => {
  const analyticsProviders: AnalyticsProvider[] = providers;

  return {
    initialize: (config: CMx.InterfaceConfig) => {
      analyticsProviders.forEach(provider => {
        provider.initialize(config);
      });
    },
    handleAction: (
      action: FluxStandardAction,
      data?: Record<string, unknown>
    ) => {
      analyticsProviders.forEach(provider => {
        provider.handleAction(action, data);
      });
    },
    handleLocationChange: (location: Location) => {
      analyticsProviders.forEach(provider => {
        provider.handleLocationChange(location);
      });
    },
    reset: () => {
      analyticsProviders.forEach(provider => {
        provider.reset();
      });
    },
    setServiceLine: (serviceLine: CMxAPI.ServiceLine) => {
      analyticsProviders.forEach(provider => {
        provider.setServiceLine(serviceLine);
      });
    },
    setContext: (context: CMx.Context) => {
      analyticsProviders.forEach(provider => {
        provider.setContext(context);
      });
    },
    setUser: (user: CMxAPI.User) => {
      analyticsProviders.forEach(provider => {
        provider.setUser(user);
      });
    },
    trackView: (viewName: string | ViewDescription) => {
      analyticsProviders.forEach(provider => {
        provider.trackView(viewName);
      });
    },
    trackAction: (
      action: ActionDescription,
      data?: Record<string, unknown>
    ) => {
      analyticsProviders.forEach(provider => {
        provider.trackAction(action, data);
      });
    }
  };
};
