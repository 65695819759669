import theme from '../../../theme';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import {
  appServiceLines,
  commonEnums,
  commonEnums as enums
} from '@codametrix/ui-common';
import * as colDefs from './case-list-columns';
import { filterCaseList } from './case-list-columns';
import { DataGrid } from '../../../components/data-grid/data-grid';
import { styles } from './case-list.styles';
import { Divider, Grid, GridItem, Text } from '@chakra-ui/react';
import { ColDef, GridOptions, RowClickedEvent } from 'ag-grid-community';
import { caseListEndPoint } from './case-list-utils';
import { SearchBar } from '../../../components/search-bar/search-bar';
import { PopUpFiltersV2 } from '../../pop-up-filter/pop-up-filtersV2';
import { useSelector } from 'react-redux';
import { RowsPerPage } from '../../../components/rows-per-page/rows-per-page';

const { FilterTypes } = enums;
const INITIAL_PAGE_SIZE = 100;

type CaseListProps = AppProps.CaseListProps &
  AppProps.PageableListProperties<any>;

const gridOptions: GridOptions = {
  suppressHorizontalScroll: true,
  sortingOrder: ['asc', 'desc', null]
};

enum FilterOptions {
  OrderNumber = 'Order Number',
  Accession = 'Accession',
  HospitalAccountRecord = 'Hospital Account Record',
  OptimeCaseId = 'OpTime Case ID'
}

export const determineColumns = (
  serviceLine?: CMx.ServiceLine,
  showOrderNumberFilter?: boolean
) => {
  let columns = colDefs.defaultColDefs;

  switch (serviceLine?.name || -1) {
    case commonEnums.ServiceLines.RADIOLOGY:
      if (showOrderNumberFilter) {
        columns = colDefs.radiologyColDefs;
      } else {
        columns = colDefs.defaultColDefs;
      }
      break;
    case commonEnums.ServiceLines.PATHOLOGY:
      if (showOrderNumberFilter) {
        columns = colDefs.pathologyColDefsOrderNumber;
      } else {
        columns = colDefs.pathologyColDefs;
      }
      break;
    case commonEnums.ServiceLines.SURGERY:
      columns = colDefs.surgColDefs;
      break;
    case commonEnums.ServiceLines.AUTOMATE:
    case commonEnums.ServiceLines.BEDSIDE_PRO:
    case commonEnums.ServiceLines.IPC_LONG_NAME:
      columns = colDefs.automateColDefs;
      break;
    default:
      columns = colDefs.defaultColDefs;
  }

  return columns;
};

const determineSelectedOption = (serviceLine?: CMx.ServiceLine) => {
  switch (serviceLine?.name || -1) {
    case commonEnums.ServiceLines.RADIOLOGY:
      return FilterOptions.Accession;
    case commonEnums.ServiceLines.PATHOLOGY:
      return FilterOptions.Accession;
    case commonEnums.ServiceLines.SURGERY:
      return FilterOptions.OptimeCaseId;
    case commonEnums.ServiceLines.AUTOMATE:
    case commonEnums.ServiceLines.BEDSIDE_PRO:
    case commonEnums.ServiceLines.IPC_LONG_NAME:
      return FilterOptions.HospitalAccountRecord;
    default:
      return FilterOptions.Accession;
  }
};

const CaseList = (props: CaseListProps) => {
  const {
    serviceLine,
    sortablePageable,
    criteriaFilter,
    activeOrgId,
    filterLoadStates,
    list,
    getDictionary,
    getStatuses,
    getTenants,
    onSelection,
    clearFilters,
    context,
    showOrderNumber
  } = props;
  let searchForms = useMemo(
    () =>
      criteriaFilter.searchForms.map(filterForm => {
        return {
          label: filterForm.label,
          value: filterForm.key
        };
      }),
    [criteriaFilter]
  );

  const showOrderNumberFilter = useSelector((state: CMx.ApplicationState) => {
    return state.cases.showOrderNumber;
  });

  const [columns, setColumns] = useState<ColDef<any>[]>(
    determineColumns(serviceLine, showOrderNumberFilter)
  );

  useEffect(() => {
    const orderNumberServiceLines = new Set([
      commonEnums.ServiceLines.RADIOLOGY,
      commonEnums.ServiceLines.PATHOLOGY
    ]);
    if (
      context?.activeContext?.healthSystemTenantId &&
      orderNumberServiceLines.has(serviceLine.name) &&
      showOrderNumber
    ) {
      showOrderNumber(
        'order_number_caselist',
        context.activeContext.healthSystemTenantId
      );
    }
  }, [serviceLine, showOrderNumber, context]);

  const selectOptionsRef = useRef<
    {
      label: string | undefined;
      value: string;
    }[]
  >(searchForms);

  const [selectedOption, setSelectedOption] = useState(
    determineSelectedOption(serviceLine)
  );

  const filterSearchForms = useCallback(() => {
    selectOptionsRef.current = searchForms.filter(
      filterForm =>
        filterForm.label !== FilterOptions.HospitalAccountRecord &&
        filterForm.label !== FilterOptions.Accession
    );
  }, [searchForms]);

  useEffect(() => {
    setColumns(determineColumns(serviceLine, showOrderNumberFilter));
    if (showOrderNumberFilter) {
      filterSearchForms();
      if (selectedOption === FilterOptions.Accession) {
        setSelectedOption(determineSelectedOption(serviceLine));
      }
    }
  }, [showOrderNumberFilter, serviceLine, filterSearchForms, selectedOption]);

  // Filtering searchForms to use HAR instead of Accession for IPC
  if (serviceLine && appServiceLines.isIPC(serviceLine.name)) {
    selectOptionsRef.current = searchForms.filter(
      filterForm =>
        filterForm.label !== FilterOptions.Accession &&
        filterForm.label !== FilterOptions.OrderNumber
    );
  } else if (showOrderNumberFilter) {
    selectOptionsRef.current = searchForms.filter(
      filterForm => filterForm.label !== FilterOptions.HospitalAccountRecord
    );
  } else {
    selectOptionsRef.current = searchForms.filter(
      filterForm =>
        filterForm.label !== FilterOptions.HospitalAccountRecord &&
        filterForm.label !== FilterOptions.OrderNumber
    );
  }

  // Rename Accession to OpTime Case ID for Surgery
  if (serviceLine && serviceLine.name === commonEnums.ServiceLines.SURGERY) {
    const index = selectOptionsRef.current.findIndex(
      form => form.label === FilterOptions.Accession
    );
    if (index !== -1) {
      selectOptionsRef.current[index].label = FilterOptions.OptimeCaseId;
    }
  }

  const handleRowSelection = (item: RowClickedEvent<CMxAPI.CaseRunV2>) => {
    // check enabled for typescript safety.
    if (item.data) {
      onSelection(item.data);
    }
  };
  const activeContextOrg = useSelector((state: CMx.ApplicationState) => {
    return state.ui.context.activeContext?.organizationName;
  });

  const classes: any = styles();
  const [searchText, setSearchText] = useState<string>('');
  const [pageSize, setPageSize] = useState<number>(INITIAL_PAGE_SIZE);
  const [filterableOptions, setFilterableOptions] = useState<
    CMxCommonApp.FilterableOption
  >(sortablePageable.filterableOptions);
  const serviceLineConfig = appServiceLines.determineServiceLineConfig(
    serviceLine
  );

  const generateNewFilterList = () => {
    const newFilterableOptions: CMxCommonApp.FilterableOption = {};
    let newFilterList: CMxCommonApp.Filter[] = [];
    const selectedOptionKey: string =
      selectOptionsRef.current.find(
        (searchForm: { label: any }) => searchForm.label === selectedOption
      )?.value ?? '';

    newFilterList = criteriaFilter.filters.filter(filter => {
      return filter.type === FilterTypes.IN;
    });

    const SearchFormMatch = criteriaFilter.searchForms.find(
      (filter: { key: string }) => {
        return filter.key === selectedOptionKey;
      }
    );

    if (SearchFormMatch) {
      const newFilter: CMxCommonApp.Filter = {
        key: selectedOptionKey,
        terms: [searchText.trim()],
        type: SearchFormMatch.type
      };
      newFilterList.push(newFilter);
    }

    newFilterableOptions.filters = newFilterList;
    return newFilterableOptions;
  };

  const handleSearchOptionChange = (event: any) => {
    setSelectedOption(event?.target?.textContent);
  };

  const handleSearchInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchText(event?.target?.value);
  };

  const handleSearchKeyPress = (
    event?: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event?.keyCode === 13) {
      const filterableOption: CMxCommonApp.FilterableOption = generateNewFilterList();
      setFilterableOptions(filterableOption);
    }
  };

  useEffect(() => {
    setFilterableOptions(sortablePageable.filterableOptions);
  }, [sortablePageable.filterableOptions]);

  const onPageSizeChange = (pageSize: number) => {
    setPageSize(pageSize);
  };

  return (
    <div style={classes.root}>
      <div style={classes.header}>
        <Text sx={classes.text}>Cases</Text>
      </div>
      <Grid
        flexDirection={'row'}
        gridTemplateRows={'min-content min-content min-content'}
        gap={theme.space[12]}
        style={classes.content}>
        <GridItem>
          <SearchBar
            selectOptions={selectOptionsRef.current}
            selectedOption={selectedOption}
            onSelectOptionChange={handleSearchOptionChange}
            onSearchInputChange={handleSearchInputChange}
            onSearchKeyPress={handleSearchKeyPress}
          />
        </GridItem>
        <GridItem>
          <Divider sx={classes.divider} />
        </GridItem>
        <GridItem>
          <Grid
            display={'flex'}
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between">
            <PopUpFiltersV2
              criteriaFilter={criteriaFilter}
              loadStates={filterLoadStates}
              sortablePageable={sortablePageable}
              list={list}
              getDictionary={getDictionary}
              getStatuses={getStatuses}
              getTenants={getTenants}
              clearFilters={clearFilters}
              activeOrgId={activeOrgId}
              setFilterableOptions={setFilterableOptions}
              isCases={true}
            />
            <RowsPerPage
              pageSize={pageSize}
              handlePageSize={onPageSizeChange}
            />
          </Grid>
        </GridItem>
        <GridItem>
          <DataGrid
            onRowClicked={handleRowSelection}
            columnDefs={columns}
            endpointData={caseListEndPoint(
              serviceLineConfig.caselistAltId || serviceLineConfig.amplifyPath,
              serviceLineConfig.caseListVersion
            )}
            filterableOptions={filterableOptions}
            serverSideInfiniteScroll
            gridOptions={gridOptions}
            contentFilter={filterCaseList}
            loaderInfo={`Just a moment while we collect ${activeContextOrg} cases with your selected criteria`}
            paginationPageSize={pageSize}
          />
        </GridItem>
      </Grid>
    </div>
  );
};

export { CaseList };
