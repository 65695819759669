import React from 'react';
import { caseUtils, objectUtils } from '@codametrix/ui-common';
import { ColDef } from 'ag-grid-community';
import classNames from 'classnames';

const truncateOptions = {
  length: 55,
  separator: ' '
};

const prop = (
  caseRun: CMxAPI.CaseRun,
  propName: string,
  optDefault = 'N/A'
) => {
  return objectUtils.get(caseRun, propName, optDefault);
};

const isCaseRunList = (content: any): content is CMxAPI.CaseRunV2[] => {
  const [firstCaseRun] = content;
  if (`service_line` in firstCaseRun) {
    return true;
  }
  return false;
};

export const filterCaseList = (data: {
  content: CMxAPI.Case[] | CMxAPI.CaseRunV2[];
}) => {
  const { content } = data;

  if (isCaseRunList(content)) {
    return content;
  }

  return content
    .filter(item => {
      return item.id !== null && item.caseRuns.length > 0;
    })
    .map(caseUtils.getCaseRun);
};

type SortDirection = 'asc' | 'desc' | null;

export const dateOfService = {
  headerName: 'SERVICE DATE',
  field: 'dateOfService',
  sortable: true,
  sort: 'desc' as SortDirection,
  valueGetter: (params: { data: CMxAPI.CaseRun }) => {
    const dateOfService = prop(params.data, 'date_of_service');

    return dateOfService;
  }
};

export const accessionNumber = {
  field: 'accessionNumber',
  headerName: 'ACCESSION',
  sortable: false,
  valueGetter: (params: { data: CMxAPI.CaseRun }) => {
    const primaryClinicalIdentifier = prop(
      params.data,
      'primary_clinical_identifier.number'
    );

    return primaryClinicalIdentifier;
  },
  cellRenderer: (params: any) => {
    return (
      <span className="sensitive-data">
        <span>{params.value}</span>
      </span>
    );
  }
};

const pointOfCare: ColDef = {
  field: 'pointOfCare',
  headerName: 'POINT OF CARE',
  sortable: false,
  cellRenderer: (params: { data: CMxAPI.CaseRun }) => {
    const pointOfCareRaw = prop(params.data, 'point_of_care');
    const pointOfCare = objectUtils.truncate(
      pointOfCareRaw,
      truncateOptions.length,
      truncateOptions.separator
    );
    return (
      <span className="case-encounter sensitive-data">
        <span
          className="encounter-description primary-info"
          title={pointOfCareRaw}>
          {pointOfCare}
        </span>
      </span>
    );
  }
};

const tenantName: ColDef = {
  field: 'tenant_name',
  headerName: 'LOCATION',
  sortable: false,
  cellRenderer: (params: { data: CMxAPI.CaseRun }) => {
    const tenantNameRaw = prop(params.data, 'tenant_name');
    const tenantName = objectUtils.truncate(
      tenantNameRaw,
      truncateOptions.length,
      truncateOptions.separator
    );
    return (
      <span className="case-encounter sensitive-data">
        <span
          className="encounter-description primary-info"
          title={tenantNameRaw}>
          {tenantName}
        </span>
      </span>
    );
  }
};

const patientMRN: ColDef = {
  field: 'patientMRN',
  headerName: 'PATIENT MRN',
  sortable: false,
  cellRenderer: (params: { data: CMxAPI.CaseRun }) => {
    const patientMRN = prop(params.data, 'patient_identifier.number');
    const patientMRNIssuer = prop(
      params.data,
      'patient_identifier.issuer',
      'SRC N/A'
    );

    return (
      <span className="case-patient">
        <span className="patient-mrn primary-info">
          <span className="gap-right">
            {patientMRNIssuer}:{' '}
            <span className="sensitive-data">{patientMRN}</span>
          </span>
        </span>
      </span>
    );
  }
};

const patientDOB: ColDef = {
  field: 'patientDOB',
  headerName: 'PATIENT DOB',
  sortable: false,
  cellRenderer: (params: { data: CMxAPI.CaseRun }) => {
    const patientDOB = prop(params.data, 'date_of_birth');
    return (
      <span className="case-patient">
        <span className="patient-mrn primary-info">
          <span className="gap-right sensitive-data">{patientDOB}</span>
        </span>
      </span>
    );
  }
};

const providerName: ColDef = {
  field: 'providerName',
  headerName: `SERVICE PROVIDER`,
  sortable: false,
  cellRenderer: (params: { data: CMxAPI.CaseRun }) => {
    const billingProviderName = prop(params.data, 'billing_provider_name');
    return (
      <div className="case-provider">
        <span className="case-provider-name primary-info sensitive-data">
          {billingProviderName}
        </span>
      </div>
    );
  }
};

const caseStatus: ColDef = {
  field: 'status',
  headerName: `STATUS`,
  sortable: false,
  cellRenderer: (params: { data: CMxAPI.CaseRun }) => {
    const status = caseUtils.deriveStatus(params.data);
    const reason = caseUtils.getStatusReason(params.data);

    let classes;
    classes = [
      'case-status',
      status.styleClass,
      { 'case-has-reason': !!reason }
    ];
    let tooltipText: string | boolean;

    tooltipText = objectUtils.isString(reason)
      ? `${status.signifier}: ${reason}`
      : `${status.signifier} case`;

    const statusEl = (
      <span
        className="tooltip"
        style={{ height: 20 }}
        data-tooltip={tooltipText}>
        <span className="info-display">
          <div className="circle" />
          {status.signifier}
        </span>
      </span>
    );

    return (
      <div className={classNames(classes)}>
        <span className="graphic">{statusEl}</span>
      </div>
    );
  }
};

const orderNumber: ColDef = {
  field: 'orderNumber',
  headerName: 'ORDER NUMBER',
  sortable: false,
  cellRenderer: (params: { data: CMxAPI.CaseRunV2 }) => {
    const orderNumber = prop(
      params.data,
      'order_number.number',
      prop(params.data, 'placer_order_number')
    );

    return <span className="sensitive-data">{orderNumber}</span>;
  }
};

// IPC

const facility: ColDef = {
  field: 'facility',
  headerName: 'FACILITY',
  sortable: false,
  valueGetter: (params: { data: CMxAPI.CaseRunV2 }) => {
    const facility = prop(params.data, 'billing_location') || 'N/A';

    return facility;
  }
};

const hospitalAccountRecord: ColDef = {
  field: 'hospitalAccountRecord',
  headerName: 'HOSPITAL ACCOUNT RECORD',
  sortable: false,
  cellRenderer: (params: { data: CMxAPI.CaseRunV2 }) => {
    const hospitalAccountRecord = prop(params.data, 'hospital_account_record');

    return <span className="sensitive-data">{hospitalAccountRecord}</span>;
  }
};

const noteFinalSignProvider: ColDef = {
  field: 'noteFinalSignProvider',
  headerName: 'NOTE FINAL SIGN PROVIDER',
  sortable: false,
  valueGetter: (params: { data: CMxAPI.CaseRunV2 }) => {
    const noteFinalSignProvider =
      prop(params.data, 'billing_provider_name') || 'N/A';

    return noteFinalSignProvider;
  }
};

const noteSpecialtyDesignation: ColDef = {
  field: 'noteSpecialtyDesignation',
  headerName: 'NOTE SPECIALTY DESIGNATION',
  sortable: false,
  valueGetter: (params: { data: CMxAPI.CaseRun }) => {
    const noteSpecialtyDesignation =
      prop(params.data, 'note_specialty_designation') || 'N/A';

    return noteSpecialtyDesignation;
  }
};

export const defaultColDefs: ColDef[] = [
  dateOfService,
  accessionNumber,
  caseStatus,
  patientMRN,
  patientDOB,
  providerName,
  tenantName,
  pointOfCare
];

export const radiologyColDefs: ColDef[] = [
  dateOfService,
  accessionNumber,
  orderNumber,
  caseStatus,
  patientMRN,
  patientDOB,
  providerName,
  tenantName,
  pointOfCare
];

export const pathologyColDefs: ColDef[] = [
  dateOfService,
  accessionNumber,
  caseStatus,
  patientMRN,
  patientDOB,
  tenantName,
  pointOfCare
];

export const pathologyColDefsOrderNumber: ColDef[] = [
  dateOfService,
  accessionNumber,
  orderNumber,
  caseStatus,
  patientMRN,
  patientDOB,
  tenantName,
  pointOfCare
];

export const surgColDefs: ColDef[] = [
  dateOfService,
  {
    ...accessionNumber,
    headerName: 'OpTime Case ID'
  },
  caseStatus,
  patientMRN,
  patientDOB,
  tenantName
];

export const automateColDefs: ColDef[] = [
  dateOfService,
  facility,
  patientMRN,
  caseStatus,
  patientDOB,
  hospitalAccountRecord,
  noteFinalSignProvider,
  noteSpecialtyDesignation
];
