/// <reference path="../types.d.ts" />

import actionCreatorFactory from 'typescript-fsa';
import { CaseDocuments } from './action-types';
import { asyncFactory } from 'typescript-fsa-redux-thunk';
import { api } from '../core/net';
import {
  caseUtils,
  appServiceLines,
  authenticationChannel,
  api as commonApi,
  objectUtils,
  documentUtils
} from '@codametrix/ui-common';

const { AutomateGeneration } = appServiceLines;
const actionCreator = actionCreatorFactory();
const createAsync = asyncFactory<CMx.CaseDocumentsState>(actionCreator);

const caseDetailAvailable = actionCreator<CaseAndProcess>(
  CaseDocuments.CASE_DETAIL
);

const docMetadataAvailable = actionCreator<CMxAPI.DocumentMetadata[]>(
  CaseDocuments.DOCUMENT_METADATA
);

const documentAvailable = actionCreator<AppProps.DocumentWithMetadata>(
  CaseDocuments.NOTE
);

type CaseIds = {
  caseId: string;
  processId: string;
};

type CaseAndProcess = {
  caseDetail: CMxAPI.Case;
  activeProcess: CMxAPI.CaseRun;
};
interface Dictionary<T> {
  [index: string]: T;
}

const getDocuments = async (
  caseRun: CMxAPI.CaseRun,
  tenantInfo: CMxAPI.Organization,
  automateGen?: string
) => {
  let filterParams: {
    tenantId: string;
    businessKey?: string;
    fillerOrderNumber?: string;
  }[];

  const isIPCServiceLine = appServiceLines.isIPC(caseRun.service_line);

  if (automateGen === AutomateGeneration.GEN3) {
    filterParams = [
      {
        tenantId: `${tenantInfo.tenantId}`,
        fillerOrderNumber: caseRun.order_filler_number.number ?? ''
      },
      {
        tenantId: `${tenantInfo.tenantId}`,
        businessKey: caseRun.caserun_uid ?? ''
      }
    ];
  } else if (isIPCServiceLine) {
    const docsSeen = new Set<string>();
    filterParams = (caseRun.notes as CMxAPI.Note[])
      ?.filter(note => note.unique_document_number)
      .map(note => {
        const docId = note.unique_document_number as string;
        docsSeen.add(docId);
        return {
          tenantId: `${tenantInfo.tenantId}`,
          uniqueDocumentNumber: docId
        };
      });
  } else {
    filterParams = [
      {
        tenantId: `${tenantInfo.tenantId}`,
        fillerOrderNumber: caseRun.order_filler_number.number
      }
    ];
  }

  // docs metadata filter
  const response: CMxAPI.DocumentMetadata[] = [];
  let docs = await fetchDocumentsByFilterParams(filterParams);

  if (docs.length === 0 && isIPCServiceLine) {
    // if no docs found, try again with businessKey
    filterParams = buildBusinessKeyFilters(caseRun, tenantInfo);
    docs = await fetchDocumentsByFilterParams(filterParams);
  }

  if (automateGen === AutomateGeneration.GEN3 || isIPCServiceLine) {
    const { MimeTypes } = documentUtils;
    const docGroupProperty = isIPCServiceLine
      ? 'uniqueDocumentNumber'
      : 'externalId';
    const groupedDocs: Dictionary<CMxAPI.DocumentMetadata[]> = objectUtils.groupBy(
      docs,
      docGroupProperty
    );

    Object.entries(groupedDocs).forEach(([externalId, docs]) => {
      // group documents by mimeType
      const groupByDocs: Dictionary<CMxAPI.DocumentMetadata[]> = objectUtils.groupBy(
        docs,
        'mimeType'
      );
      const precedence = [MimeTypes.HTML, MimeTypes.PDF, MimeTypes.TEXT];
      const doc = documentUtils.firstAvailable(groupByDocs, precedence);
      if (doc) {
        response.push(doc);
      }
    });
  } else {
    response.push(...docs);
  }

  return response;
};

/**
 * Builds an array of filter parameters for fetching documents based on the case run's business key.
 *
 * @param caseRun - The case run object containing the business key and child case list.
 * @param tenantInfo - The tenant information object containing the tenant ID.
 * @returns An array of filter parameters, each with a tenantId and businessKey.
 */
const buildBusinessKeyFilters = (
  caseRun: CMxAPI.CaseRun,
  tenantInfo: CMxAPI.Organization
) => {
  const filterParams = [
    {
      tenantId: `${tenantInfo.tenantId}`,
      businessKey: caseRun.caserun_uid ?? ''
    }
  ];
  caseRun.child_case_list?.forEach(childCase => {
    filterParams.push({
      tenantId: `${tenantInfo.tenantId}`,
      businessKey: childCase.caserun_uid ?? ''
    });
  });
  return filterParams;
};

/**
 * Fetches a list of documents based on the provided filter parameters.
 *
 * @param filterParams - An array of filter parameters, each containing a tenantId and optional businessKey or fillerOrderNumber.
 * @returns A Promise that resolves to an array of `CMxAPI.DocumentMetadata` objects representing the fetched documents.
 */
const fetchDocumentsByFilterParams = async (
  filterParams: {
    tenantId: string;
    businessKey?: string;
    fillerOrderNumber?: string;
  }[]
) => {
  const documentRequests = filterParams.map(filterParam => {
    //@ts-ignore
    const docFilter = new URLSearchParams(filterParam);
    return api<CMxAPI.DocumentMetadata[]>({
      endpoint: `/documents/v2?${docFilter}`
    });
  });

  // get list of documents
  const allPromises = await Promise.all<CMxAPI.DocumentMetadata[]>(
    documentRequests
  );
  const docs = allPromises.flatMap(doc => doc);
  return docs;
};

const fetchCaseRunDocuments = createAsync<
  CMxAPI.CaseRun,
  CMxAPI.DocumentMetadata[],
  CMxCommonApp.SubmitError
>(CaseDocuments.FETCH_CASERUN_DOCUMENTS, async (activeCaseRun, dispatch) => {
  const tenantInfo = await api<CMxAPI.Organization>({
    endpoint: `/organization/by-name/${activeCaseRun.tenant_name}/v1`
  });

  const serviceLineConfig = appServiceLines.determineServiceLineConfig({
    name: activeCaseRun.service_line,
    caseIdentifier: activeCaseRun.case_uid ?? ''
  });
  const automateGen = serviceLineConfig.automateGen;

  let docs = await getDocuments(activeCaseRun, tenantInfo, automateGen);

  const noteRelated = new Set(['HL7', 'NOTE']);

  docs = docs.filter(doc => {
    // initialize document match to true.
    let documentMatch = true;
    // for DFTs we utilize a different external id, not related to the case's process instance
    if (
      noteRelated.has(doc.documentCategory) &&
      activeCaseRun.external_id !== null &&
      doc.externalId !== null
    ) {
      // radiology documents support an external id. surg and path don't for the moment.
      documentMatch = doc.externalId === activeCaseRun.external_id;
    }
    return documentMatch;
  });

  dispatch(docMetadataAvailable(docs));
  const docGroups = objectUtils.groupBy(docs, 'documentCategory');
  // Find the first document group to show in the UI.
  // see https://github.com/CodaMetrix/qa-common-interface/blob/dev/src/main/java/com/codametrix/qa/common/library/model/documentation/DocumentCategoryEnum.java
  const docCategories = [
    'HL7',
    'DFT_IN',
    'DFT_OUT',
    'DFT_EHR',
    'DFT_TRACK',
    'NOTE',
    'PREPROCESSED_MESSAGE',
    'EVIDENCE',
    'CASE_JSON'
  ];
  let categories = docCategories.filter(
    group => objectUtils.get(docGroups, group)?.length
  );
  // if no matching docCategory is found search
  // for any document category that might be out there.
  if (!categories.length) {
    categories = Object.keys(docGroups).filter(
      group => objectUtils.get(docGroups, group)?.length
    );
  }

  if (categories.length) {
    const [firstDocGroup] = categories;
    const [metadata] = objectUtils.get(docGroups, firstDocGroup);
    await dispatch(fetchDocument(metadata));
  }
  return docs;
});

const fetchDocument = createAsync<
  CMxAPI.DocumentMetadata,
  AppProps.DocumentWithMetadata,
  CMxCommonApp.SubmitError
>(CaseDocuments.FETCH_DOCUMENT, async (metadata, dispatch) => {
  const endpoint = `/documents/download/${metadata.documentId}/v1`;
  const content = await commonApi.request(endpoint);
  const note: CMxAPI.DocumentServiceNote = { Note: await content.text() };
  const docWithMetadata = { note, metadata };
  dispatch(documentAvailable(docWithMetadata));

  return docWithMetadata;
});

const loadDocuments = createAsync<
  CaseIds,
  CMxAPI.DocumentMetadata[],
  CMxCommonApp.SubmitError
>(CaseDocuments.LOAD, async (caseIds, dispatch) => {
  await authenticationChannel.ready;

  let { caseId, processId } = caseIds;

  const url = new URL(window.location.toString());
  const serviceLine = url.searchParams.get('serviceLine') ?? '';
  const caseIdentifier = url.searchParams.get('caseId') ?? '';
  const serviceLineConfig = appServiceLines.determineServiceLineConfig({
    name: serviceLine,
    caseIdentifier
  });

  const caseParams = new URLSearchParams(serviceLineConfig.caseDetailParms);

  const expandedCaseDetail: CMxAPI.Case = await api<CMxAPI.Case>({
    endpoint: `${serviceLineConfig.path}/caseinstance/${caseId}/details/${
      serviceLineConfig.caseDetailVersion
    }?${caseParams.toString()}`
  });

  const processIdNumber = parseInt(processId);
  const activeCaseRun = caseUtils.getCaseRun(
    expandedCaseDetail,
    processIdNumber
  );

  const caseDetailAndProcess = {
    caseDetail: expandedCaseDetail,
    activeProcess: activeCaseRun
  };

  dispatch(caseDetailAvailable(caseDetailAndProcess));
  return await dispatch(fetchCaseRunDocuments(activeCaseRun));
});

export {
  loadDocuments,
  caseDetailAvailable,
  docMetadataAvailable,
  documentAvailable,
  fetchDocument,
  fetchCaseRunDocuments,
  getDocuments
};
