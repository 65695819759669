/// <reference path="../../types.d.ts" />

import classnames from 'classnames';
import React from 'react';
import {
  DataGrid,
  Pagination,
  LoadingSlugs,
  SearchBar,
  Card,
  SelectList
} from '@codametrix/ui-components';

import { DatePicker } from '../../components/date-picker/datepicker';

import { CMxComponents } from '@codametrix/ui-components/src/types';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { defaultSortableOption } from '../../core/pageable-options';
import { defaultCriteriaFilter } from '../../core/criteria-filter';

import '../../styles/grid-common.scss';
import '../../styles/cmx.scss';
import './pageable-list.scss';
import PopUpFilter from '../pop-up-filter/pop-up-filters';
import { commonEnums as enums } from '@codametrix/ui-common';
import { LOAD_STATES } from '../../reducers/common';
import classNames from 'classnames';
const { FilterTypes } = enums;

type PageableListState = {
  filterFormOpen: boolean;
  selectedForm: number | undefined;
};

const pageSizeOptions: CMxComponents.SelectListOption[] = [
  {
    label: '25',
    value: 25
  },
  {
    label: '50',
    value: 50
  },
  {
    label: '100',
    value: 100
  },
  {
    label: '200',
    value: 200
  }
];

export const dummy = () => {
  return DatePicker;
};

class PageableList<T> extends React.Component<
  AppProps.PageableListProperties<T>,
  PageableListState
> {
  static defaultProps = {
    list: (options: CMxCommonApp.SortablePageable<any>) => {},
    onNavigate: () => {},
    getDictionary: (dictionaryName: AppProps.CategoryParams) => {},
    getStatuses: (dictionaryName: AppProps.CategoryParams) => {},
    onAction: (content: any) => {},
    clearAllFilters: () => {},
    pageableOptions: defaultSortableOption,
    criteriaFilter: defaultCriteriaFilter,
    criteriaLoadState: LOAD_STATES.done
  };

  constructor(props: any) {
    super(props);
    this._handleFilterRemove = this._handleFilterRemove.bind(this);
    this._handleSearch = this._handleSearch.bind(this);
  }

  componentDidMount() {
    const url = new URL(document.location.toString());
    if (!url.searchParams.has('back')) {
      this.props.list({ ...this.props.sortablePageable });
    } else {
      this.props.onNavigate();
    }
  }

  _handleSearch(event: CustomEvent) {
    const {
      detail: {
        value: { value, key }
      }
    } = event;

    let filters: CMxCommonApp.Filter[] = [];

    filters = this.props.criteriaFilter.filters.filter(filter => {
      return filter.type === FilterTypes.IN;
    });

    const SearchFormMatch = this.props.criteriaFilter.searchForms.find(
      filter => {
        return filter.key === key;
      }
    );

    //Skip Empty Searches
    //Ensure we have a matching search form
    //Ensure value from the custom event is a string
    if (
      value !== '' &&
      SearchFormMatch &&
      (typeof value === 'string' || value instanceof String)
    ) {
      let newFilter: CMxCommonApp.Filter = {
        key: key,
        terms: [value.trim()],
        type: SearchFormMatch.type
      };
      filters.push(newFilter);
    }

    var listOptions = this.props.sortablePageable;
    listOptions.filterableOptions.filters = filters;
    listOptions.filterableOptions.dateFilter = undefined;

    listOptions.pageableDefinition.pageable.pageNumber = 0;
    listOptions.pageableDefinition.number = 0;
    listOptions.pageableDefinition.first = true;
    listOptions.pageableDefinition.last = false;
    listOptions.pageableDefinition.pageable.offset = 0;

    this.props.list(listOptions);
  }

  _handleFilterRemove(event: React.MouseEvent) {
    event.preventDefault();
    const target = event.target;
    const parentChip = (target as HTMLElement)?.closest('.chip');
    const chipClose = (target as HTMLElement)?.closest('.chip-close');
    if (parentChip === null || chipClose === null) {
      return;
    }
    const filterKey = parentChip.getAttribute('id');

    const currentTerm = chipClose.getAttribute('id');

    var currentFilter = this.props.criteriaFilter.filters.find(filter => {
      return filter.key === filterKey;
    });

    if (!currentFilter) {
      return;
    }
    var filterableOptions;
    if (currentFilter.terms.length === 1) {
      filterableOptions = this.props.criteriaFilter.filters.filter(filter => {
        return filter.key !== filterKey;
      });
    } else {
      currentFilter.terms = currentFilter.terms.filter(term => {
        return term !== currentTerm;
      });

      filterableOptions = this.props.criteriaFilter.filters;
      //modify filter in place to maintin order in ui
      filterableOptions.forEach(filter => {
        if (filter.key === filterKey && currentFilter) {
          filter = currentFilter;
        }
      });
    }
    this.props.list({
      ...this.props.sortablePageable,
      filterableOptions: filterableOptions
    });
  }

  render() {
    const { props } = this;
    const {
      loadState,
      filterLoadStates,
      items,
      columnDefs,
      onSelection,
      itemName,
      sortablePageable,
      criteriaFilter,
      idField,
      children,
      criteriaLoadState,
      onAction
    } = props;

    const searchForms = criteriaFilter.searchForms.map(filterForm => {
      const isActiveSearch = criteriaFilter.filters.some(filter => {
        return filter.key === filterForm.key;
      });
      return {
        ...filterForm,
        isSelected: isActiveSearch
      };
    });

    const inFlight = loadState.loading || loadState.initial;
    const classes = [
      'pageable-list-view',
      'layout-full',
      { 'request-in-flight': inFlight }
    ];

    const itemCountClasses = [
      { 'request-in-flight': inFlight },
      'showing',
      'qa-item-count'
    ];
    const { pageableDefinition: pageable } = sortablePageable;
    const offsetInfo = pageable.pageable;
    let offset;

    if (pageable.totalElements) {
      offset = {
        start: offsetInfo.offset + 1,
        end: Math.min(
          offsetInfo.offset + offsetInfo.pageSize,
          pageable.totalElements
        )
      };
    } else {
      offset = {
        start: 0,
        end: 0
      };
    }

    const onPaginationClick = (action: CMxComponents.PaginationAction) => {
      if (!inFlight) {
        const {
          sortablePageable: {
            pageableDefinition: pageable,
            sortableOptions,
            filterableOptions
          }
        } = this.props;

        const retAction: CMxCommonApp.SortablePageable<any> = {
          pageableDefinition: {
            ...pageable,
            pageable: {
              ...pageable.pageable,
              pageNumber: action.index,
              pageSize: action.size
            }
          },
          sortableOptions,
          filterableOptions
        };
        this.props.list(retAction);
      }
    };

    const _handlePageResize = (event: CustomEvent) => {
      const pageAction: CMxComponents.PaginationAction = {
        index: 0,
        size: event.detail.value
      };
      onPaginationClick(pageAction);
    };

    const onSortClick = (action: CMxComponents.SortAction) => {
      if (!inFlight) {
        const {
          sortablePageable: {
            pageableDefinition: pageable,
            sortableOptions,
            filterableOptions
          }
        } = this.props;

        const sortableKeys: string[] = columnDefs.find(def => {
          return def.key === action.key;
        }).sortableKeys;

        sortableKeys.forEach(sortableKey => {
          // Use the key to find a matching sortableOption, to be toggled by the action
          const match = sortableOptions.find(
            option => option.sortField === sortableKey
          );

          if (match !== undefined) {
            if (action.direction === 'none') {
              // If the new sort direction is "none" then delete the sortable option
              sortableOptions.splice(
                sortableOptions.findIndex(
                  option => option.sortField === match.sortField
                ),
                1
              );
            }
            if (action.direction === 'asc' || action.direction === 'desc') {
              // If the new sort direction is asc/desc then change the direction
              sortableOptions[
                sortableOptions.findIndex(
                  option => option.sortField === match.sortField
                )
              ].sortDirection = action.direction;
            }
          } else {
            // If no sortable option exists, Add a new sort parameter to sortable options
            sortableOptions.push({
              sortField: sortableKey,
              sortDirection: action.direction
            });
          }
        });

        //Change the defaultSort in the column definition since data-grid needs the sort direction from props
        columnDefs[
          columnDefs.findIndex(column => column.key === action.key)
        ].defaultSort = action.direction;

        const retAction: CMxCommonApp.SortablePageable<any> = {
          pageableDefinition: {
            ...pageable
          },
          sortableOptions,
          filterableOptions
        };
        this.props.list(retAction);
      }
    };

    const inner = loadState.initial ? (
      <Card className="loading-card" data-testid="loading-slugs">
        <LoadingSlugs numberItems={3} />
      </Card>
    ) : items.length ? (
      <DataGrid
        onRowSelection={onSelection}
        onSortSelection={onSortClick}
        columnDefs={columnDefs}
        items={items}
        idField={idField || 'id'}
        onAction={onAction}
        data-testid="data-grid"
      />
    ) : (
      <h4
        className="content-vacant qa-content-vacant"
        data-testid="content-vacant">
        {' '}
        No {itemName} found
      </h4>
    );

    var defaultInput = sortablePageable.filterableOptions.filters?.find(
      filter => {
        return criteriaFilter.searchForms.find(form => filter.key === form.key);
      }
    )?.terms[0];
    return (
      <div className={classnames(classes)}>
        <div className="container heading-row layout-inner-header">
          {criteriaFilter.searchForms.length ||
          criteriaFilter.filterForms.length ? (
            <Card className={'header-card'}>
              {criteriaLoadState === LOAD_STATES.done ? (
                <span>
                  {criteriaFilter.searchForms?.length ? (
                    <div>
                      <div>
                        <SearchBar
                          FieldDefinitions={searchForms}
                          placeholder={'Search'}
                          defaultInput={defaultInput}
                          onSubmit={this._handleSearch}></SearchBar>
                      </div>
                    </div>
                  ) : (
                    false
                  )}

                  {criteriaFilter.filterForms?.length ? (
                    <div>
                      <div className="divider"></div>

                      <div>
                        <PopUpFilter
                          criteriaFilter={criteriaFilter}
                          loadStates={filterLoadStates}
                          sortablePageable={this.props.sortablePageable}
                          list={this.props.list}
                          getDictionary={this.props.getDictionary}
                          getStatuses={this.props.getStatuses}
                          getTenants={this.props.getTenants}
                          clearFilters={this.props.clearFilters}
                          activeOrgId={this.props.activeOrgId}
                        />
                      </div>
                    </div>
                  ) : (
                    false
                  )}
                </span>
              ) : (
                <div className="criteria-loading qa-criteria-loading">
                  <LoadingSlugs numberItems={1} />
                </div>
              )}
            </Card>
          ) : (
            false
          )}
        </div>

        <div className="pageable-gutter">
          <span className={classNames(itemCountClasses)}>
            {offset.start}&ndash;{offset.end}{' '}
            <span style={{ fontSize: 12 }}>OF&nbsp;</span>
            {pageable.totalElements}{' '}
            <span style={{ fontSize: 12 }}>{itemName.toUpperCase()}</span>
            <span className={'page-size-select'} style={{ fontSize: 12 }}>
              Items Per Page
            </span>
            <span className={'qa-page-size-select-list'}>
              <SelectList
                name={'Page Size'}
                options={pageSizeOptions}
                value={pageable.size.toString()}
                onChange={_handlePageResize}
              />
            </span>
          </span>

          <div className={'m-2'}>{children}</div>
        </div>

        <div className="layout-inner-body pageable-list-inner-body ">
          <SwitchTransition mode={'out-in'}>
            <CSSTransition
              classNames="switch-views"
              timeout={150}
              key={loadState.loading ? 'list-loading' : 'list-loaded'}>
              {inner}
            </CSSTransition>
          </SwitchTransition>

          <div className="pagination-controls">
            <Pagination pageable={pageable} onSelect={onPaginationClick} />
          </div>
        </div>
      </div>
    );
  }
}

export default PageableList;
