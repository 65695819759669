/// <reference path="../../types.d.ts" />
import { Button } from '../../components/button/button';
import { ButtonSize } from '../../core/enums';
import { ButtonVariant } from '../../core/enums';
import Input from '../../components/input/input';

import React, { FormEvent } from 'react';
import { GitTag } from '../../core/git-info';
// assets

import './login.scss';
import ContextChoiceForm from '../../views/context/context-choice-form';
import { Route } from 'react-router-dom';
import { Eula } from '../../views/eula/eula';
import { getStyledBackgroundImage } from '../../core/login/background-image';
import ServiceLines from '../../views/service-lines/service-line-choice-form';
import ForgotPasswordForm from '../../views/forgot-password/forgot-password-component';
import resetPasswordComponent from '../../views/forgot-password/reset-password-component';
import PasswordChangeForm from '../../views/password-change/password-change-component';
import { ConfirmEmailModal } from './confirm-email-modal/confirm-email-modal';
import ssoLoginComponent from '../../views/sso-login/sso-login-component';
import { Logotype4CBlack } from '@codametrix/verdigris';

interface LoginItem {
  doLogin: any;
  handleOrganizationChoice: (orgChoice: CMx.OrganizationFormChoice) => void;
  handlePasswordChange: (passwordData: CMx.NewPasswordData) => void;
  handleForgotPassword: () => void;
  context: CMx.Context;
  contexts: CMxAPI.OrganizationContext[];
  error: boolean;
  submitting: boolean;
  forcePasswordChange?: boolean;
  user: CMxAPI.User;
  message: string;
  homepagePreference?: CMxAPI.Preference;
  emailConfirmationModalOpen: boolean;
}

type LoginFormProps = {
  error: boolean;
  submitting: boolean;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: (event: FormEvent<Element>) => void;
  handleForgotPassword: () => void;
  message: string;
};

const LoginForm: React.FC<LoginFormProps> = (props: LoginFormProps) => {
  const {
    error,
    handleInputChange,
    handleSubmit,
    handleForgotPassword,
    submitting,
    message
  } = props;

  const handleForgotPasswordClick = (e?: React.MouseEvent<HTMLElement>) => {
    if (e) {
      e.preventDefault();
      handleForgotPassword();
    }
  };

  return (
    <div>
      <form className="login-form" onSubmit={handleSubmit}>
        <fieldset disabled={submitting}>
          {!!error && (
            <div className="error-msg">
              <i className="fa fa-times-circle"></i>
              &nbsp;
              {message ? message : 'Login Failed: Invalid Username or Password'}
            </div>
          )}

          <div className="form-group">
            <div className="form-row">
              <label className="form-label" htmlFor="input-email-1">
                Username
              </label>
              <Input
                autoFocus
                onChange={handleInputChange}
                name={'username'}
                placeholder={''}
                type={'text'}
                className={error ? 'form-input is-error' : 'form-input'}
              />
            </div>

            <div className="form-row">
              <label className="form-label" htmlFor="input-password-1">
                Password
              </label>
              <Input
                autoFocus
                onChange={handleInputChange}
                name={'password'}
                placeholder={''}
                type={'password'}
                className={error ? 'form-input is-error' : 'form-input'}
                id="input-password-1"
              />
            </div>
            {/* @ts-ignore */}
            <div className="form-buttons">
              <Button
                size={ButtonSize.MD}
                label={'Login'}
                variant={ButtonVariant.PRIMARY}
                name="Login"
                dataTestId="login"
                type="submit"
              />
              <Button
                size={ButtonSize.MD}
                label="Forgot Password"
                variant={ButtonVariant.LINKS}
                dataTestId="forgotPassword"
                onClick={handleForgotPasswordClick}
              />
            </div>
          </div>
        </fieldset>
      </form>
    </div>
  );
};

class Login extends React.Component<LoginItem> {
  static defaultProps = {
    doLogin: () => {},
    error: false
  };

  constructor(props: any) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleSubmit(event: FormEvent) {
    event.preventDefault();
    this.props.doLogin(this.state);
  }

  handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  render() {
    const {
      error,
      submitting,
      contexts,
      context,
      handleOrganizationChoice,
      message,
      handleForgotPassword,
      user,
      emailConfirmationModalOpen
    } = this.props;

    const bgStyle = getStyledBackgroundImage();
    return (
      <div className="exterior-container">
        {emailConfirmationModalOpen ? <ConfirmEmailModal /> : null}
        <div className="exterior-header" style={bgStyle}></div>
        <div className="exterior-body">
          <Route path="/eula/" exact component={Eula} />
          <Logotype4CBlack titleText="Codametrix" iconClass="exterior-icon" />
          <div className="column action-container">
            <div className="action">
              <Route
                path="/update-password/"
                exact
                component={PasswordChangeForm}
              />
              <Route
                path="/contexts/"
                exact
                render={() => {
                  return (
                    <ContextChoiceForm
                      context={context && context.activeContext}
                      handleOrganizationChoice={handleOrganizationChoice}
                      contexts={contexts}
                      user={user}
                    />
                  );
                }}
              />
              <Route path="/service-lines/" exact component={ServiceLines} />
              <Route
                path="/forgot-password/"
                exact
                component={ForgotPasswordForm}
              />
              <Route
                path="/forgot-password/reset"
                exact
                component={resetPasswordComponent}
              />
              <Route
                path="/sso/:orgCode?"
                exact
                component={ssoLoginComponent}
              />
              <Route
                path="/"
                exact
                render={() => {
                  return (
                    <LoginForm
                      submitting={submitting}
                      error={error}
                      handleInputChange={this.handleInputChange}
                      handleSubmit={this.handleSubmit}
                      message={message}
                      handleForgotPassword={handleForgotPassword}
                    />
                  );
                }}
              />
            </div>
            <div className="warning-message-container">
              <p className={'warning-message'}>
                By clicking the Login button, you confirm that you have the
                right and authority to access, use and disclose protected health
                information (PHI) on this platform. Unauthorized users are
                expressly prohibited.
                <span className="imo-message">
                  Powered by IMO® Terminology. © {new Date().getFullYear()}
                </span>
              </p>
            </div>
          </div>
        </div>
        <GitTag />
      </div>
    );
  }
}

export default Login;
