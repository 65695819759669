import { ViewMatcher } from './analytics-types';

export const dataDogViewMatchers: ViewMatcher[] = [
  {
    pattern: /^\/cmx\/cases\/([a-zA-Z\s-]+)\/([a-zA-Z0-9-]+)$/,
    getDescription: matches => ({
      name: 'Open Case',
      serviceline: matches[1]
    })
  },
  {
    pattern: /^\/cmx\/cases\/([a-zA-Z\s-]+)\/$/,
    getDescription: matches => ({
      name: 'Open Cases',
      serviceline: matches[1]
    })
  },
  {
    pattern: /^\/cmx\/worklist\/.*?\/case\/.*?\/case-run\/.*?$/,
    getDescription: () => ({
      name: 'Open Coder Screen'
    })
  },
  {
    pattern: /^\/cmx\/worklist\/$/,
    getDescription: matches => ({
      name: 'Open Worklist'
    })
  },
  {
    pattern: /^\/cmx\/queue-settings\/$/,
    getDescription: matches => ({
      name: 'Open Queue settings'
    })
  },
  {
    pattern: /^\/cmx\/coding-quality\/$/,
    getDescription: matches => ({
      name: 'Open Coding Quality'
    })
  },
  {
    pattern: /^\/cmx\/admin\/orgs\//,
    getDescription: matches => ({
      name: 'Open Admin settings'
    })
  }
];
