import { buildRoleSet } from '../../reducers/users/roles';
import { AnalyticsProvider, ViewDescription } from './analytics-types';
import { _noOpProvider } from './base-provider';
import mixpanelBrowser from 'mixpanel-browser';

let globalUserId: any;

const setUserOrgs = (context: CMx.Context) => {
  return context.contexts.map((context: CMxAPI.OrganizationContext) =>
    context.organizationCode.toString()
  );
};

export const mixpanelFactory = (): AnalyticsProvider => {
  let initialized = false;

  return {
    ..._noOpProvider,
    initialize: (config: CMx.InterfaceConfig) => {
      if (!initialized && config?.mixpanelApiKey !== undefined) {
        initialized = true;
        mixpanelBrowser.init(config.mixpanelApiKey, {
          api_host: window.location.origin,
          debug: true
        });
      }
    },
    setContext: (context: CMx.Context) => {
      if (!initialized) {
        return;
      }
      const activeRoles = buildRoleSet(context);
      const activeOrg = context.activeContext?.organizationCode;
      const availableOrgs = setUserOrgs(context);
      const { user } = context;

      mixpanelBrowser.people.set({
        'Recently Active Roles': activeRoles,
        'Recently Active Organization': activeOrg,
        'Available Organizations': availableOrgs
      });

      mixpanelBrowser.people.set_once({
        $name: user.firstName + ' ' + user.lastName,
        $email: user.username
      });
    },
    setUser: (user: CMxAPI.User) => {
      if (!initialized) {
        return;
      }
      if (user.id && user.id !== globalUserId) {
        globalUserId = user.id;
        mixpanelBrowser.identify(`${user.id}`);
      }
    },
    trackView: (viewName: string | ViewDescription) => {
      if (!initialized) {
        return;
      }
      mixpanelBrowser.track(
        typeof viewName === 'string' ? viewName : viewName.name,
        {}
      );
    },
    handleAction: (action: any, data?: Record<string, unknown>) => {
      if (!initialized) {
        return;
      }
      if (!(action.meta as { analytics?: boolean })?.analytics) {
        return;
      }
      const actionType = action.type.replace(/[-_]/g, ' ');
      mixpanelBrowser.track(actionType, {});
    },
    reset: () => {
      if (!initialized) {
        return;
      }
      mixpanelBrowser.reset();
    }
  };
};
