import theme from '../../theme';

export const styles = () => ({
  btnHeader: {
    marginLeft: 'auto',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    columnGap: theme.space[20]
  },
  ssoForm: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.space[20]
  },
  ssoFormText: {
    ...theme.textStyles['Body 2'],
    lineHeight: 1.5
  }
});
