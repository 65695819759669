import {
  ActionDescription,
  AnalyticsProvider,
  ViewDescription
} from './analytics-types';

export const _noOpProvider: AnalyticsProvider = {
  initialize: () => {},
  handleAction: (
    action: FluxStandardAction,
    data?: Record<string, unknown>
  ) => {},
  handleLocationChange: (location: Location) => {},
  setContext: (context: CMx.Context) => {},
  setUser: (user: CMxAPI.User) => {},
  setServiceLine: (serviceLine: CMxAPI.ServiceLine) => {},
  trackView: (viewName: string | ViewDescription) => {},
  trackAction: (
    action: ActionDescription,
    data?: Record<string, unknown>
  ) => {},
  reset: () => {}
};
