/// <reference path="../types.d.ts" />

import InitialState from '../reducers/initial-state';
import { cmxReducerFactory } from './reducer-utils';
import * as AccountSettingsActions from '../actions/account-settings';
import { uiActions } from '@codametrix/ui-common';
import {
  defaultServiceLine as serviceLineField,
  defaultOrg as orgField,
  defaultHomepage as homepageField,
  noDefaultServiceLineOption,
  noDefaultOrgOption,
  noDefaultHomepageOption
} from '../reducers/state/account-settings';
import { retrieveGroups } from '../core/navigation';

const getHomepageOptions = (
  activeContext: CMxAPI.OrganizationContext | null
) => {
  const navOptions = activeContext ? retrieveGroups(activeContext, '*') : [];

  let homepageFieldOptions: { label: string; path: string }[] = [];

  navOptions
    // TODO: ZEM-690 - remove this filter when we can show provider_directory option for homepage defaults
    .filter(group => group.key !== 'provider_directory')
    .forEach(group => {
      return group.options.forEach(option => {
        if (option.subActions.length > 0) {
          option.subActions.forEach(subAction => {
            if (subAction.enabled) {
              homepageFieldOptions.push({
                label: option.label + ': ' + subAction.displayName,
                path: subAction.path ?? ''
              });
            }
          });
        } else {
          if (option.enabled) {
            homepageFieldOptions.push({
              label: option.label ?? '',
              path: option.path
            });
          }
        }
      });
    });

  return homepageFieldOptions;
};

const updateFieldGroups = (
  context: CMx.Context,
  defaultOrg: number | undefined
) => {
  const { contexts, activeContext } = context;

  const defaultOrgField = {
    ...orgField,
    options: [...contexts, noDefaultOrgOption]
  };

  // Show all service lines as options
  const serviceLineOptions = contexts
    .map(context => context.serviceLines)
    .flat()
    .filter((serviceLine, index, self) => {
      return self.findIndex(s => s.id === serviceLine.id) === index;
    });

  const defaultServiceLineField = {
    ...serviceLineField,
    options: [...serviceLineOptions, noDefaultServiceLineOption]
  };

  const homepageFieldOptions = getHomepageOptions(activeContext);

  const defaultHomepageField = {
    ...homepageField,
    options: [...homepageFieldOptions, noDefaultHomepageOption]
  };

  const updatedFieldGroup: CMxCommonApp.FieldGroup = {
    label: 'Account Defaults',
    fields: [defaultOrgField, defaultServiceLineField]
  };

  const selectedOrg = contexts.find(x => {
    return x.organizationId === defaultOrg;
  });
  const contextDisplay = selectedOrg?.displayName ?? 'Organization';

  const contextFieldGroup: CMxCommonApp.FieldGroup = {
    label: `${contextDisplay} Defaults`,
    fields: [defaultHomepageField]
  };

  return [updatedFieldGroup, contextFieldGroup];
};

const reducer = cmxReducerFactory(InitialState.accountSettings)
  .case(AccountSettingsActions.toggleIsOpen, (state, isOpen) => {
    return {
      ...state,
      isOpen
    };
  })
  .cases(
    [uiActions.chooseContext, AccountSettingsActions.updateFormDefinition],
    (state, payload) => {
      const { defaultOrg } = state.accountDefaults.formObject;

      const fieldGroups = updateFieldGroups(payload, defaultOrg);

      const formDefinition: CMxCommonApp.FormDefintion = {
        ...state.accountDefaults.formDefinition,
        fieldGroups
      };

      return {
        ...state,
        accountDefaults: {
          ...state.accountDefaults,
          formDefinition
        }
      };
    }
  )
  .case(AccountSettingsActions.saveFormData, (state, payload) => {
    const { defaultOrg, defaultServiceLine, defaultHomepage } = payload;
    const oldFormObj = state.accountDefaults.formObject;
    const formObject = {
      defaultOrg: defaultOrg ?? oldFormObj.defaultOrg,
      defaultServiceLine: defaultServiceLine ?? oldFormObj.defaultServiceLine,
      defaultHomepage: defaultHomepage?.path.length
        ? defaultHomepage
        : oldFormObj.defaultHomepage
    };
    return {
      ...state,
      accountDefaults: {
        ...state.accountDefaults,
        formObject
      }
    };
  });

export default reducer;
