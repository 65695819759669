import { UserContext, Login, ServiceLine } from '../../actions/action-types';
import {
  commonActions,
  commonEnums,
  cmxTypeguards
} from '@codametrix/ui-common';
import { MiddlewareProcessor, middleWareWrapper } from './middleware-utils';
import { analyticsFactory } from '../analytics';
import { dataDogFactory } from '../analytics/data-dog';
import { dataDogViewMatchers } from '../analytics/data-dog-view-matchers';
import { mixpanelFactory } from '../analytics/mixpanel';

const { UserInterface, Bench, AttachDocs, MergeCases, Launch } = commonActions;
const { ProcessInstanceActions } = commonEnums;

// Factory functions for analytics providers
const datadog = dataDogFactory(dataDogViewMatchers);
const mixpanel = mixpanelFactory();
const analytics = analyticsFactory([datadog, mixpanel]);

// Reference data
// minitab values don't seem to be exposed, but needed for determining correct 'view'
type MiniTabKey = 'Related' | 'Activity' | 'Charges' | 'Improvements';

const miniTabPrettyNames: Record<MiniTabKey, string> = {
  Related: 'Open Related Case Tab',
  Activity: 'Open Case Activity Tab',
  Charges: 'Open Case Charges Tab',
  Improvements: 'Open Improvements Tab'
};

/**
 * Middleware inspecting dispatched Redux actions - currently for DataDog views and MixPanel events
 *
 * MixPanel observes Redux actions and sets up a sense of sessions (via "events")
 *
 * DataDog has three entry points to its use of "views" (it is also doing deeper monitoring of events in the page,
 * enabling it to time things like query time and page responsiveness:)
 *  - monitor the URL (but we manually take this over so that we can replace partial URLs with 'friendlier' view names
 *  - observe redux actions
 *  - manually call window.DD_RUM.startView("View Name");, like in a click handler
 *
 * We currently only engage the first two but the other is available in a global, decoupled way.
 **/
const analyticsProcessor: MiddlewareProcessor = (api, next, action) => {
  if (!cmxTypeguards.isFSA(action)) {
    return next(action);
  }

  const {
    ui: { activeUser, config }
  }: CMx.ApplicationState = api.getState();

  // this is called every time, but it is a one-off, so it's not a big deal
  analytics.initialize(config);

  // monitor user life-cycle events and provide
  // default action handling.
  switch (action.type) {
    case Login.LOGOUT:
    case Login.LOGIN_STARTED:
      analytics.reset();
      break;
    //normal login process
    case UserInterface.SET_ACTIVE_USER:
      analytics.setUser(activeUser);
      break;
    case UserContext.CHOOSE_CONTEXT:
      analytics.setUser(activeUser);
      analytics.setContext(action.payload);
      break;
    case ServiceLine.SAVE_SERVICE_LINE:
      analytics.setServiceLine(action.payload);
      break;
    // don't know yet how to handle once a tenant or service line is changed after log in.
    default:
      break;
  }

  /**
   * View interceptor. Certain Redux actions (often ones involving modals etc)
   * effectively change the view but only would be observable here (at least without
   * changing the event handler)
   **/

  switch (action.type) {
    case Bench.SET_ACTIVE_MINI_TAB:
      analytics.trackView(
        miniTabPrettyNames[action.payload as MiniTabKey] || 'Open Case Tab'
      );
      break;
    case AttachDocs.SHOW_ATTACH_DOCS_MODAL:
      analytics.trackView('Open Add Doc');
      break;
    case `${MergeCases.FETCH_MERGEABLE_CASES}_STARTED`:
      analytics.trackView('Open Merge Window');
      break;
    case `${MergeCases.FETCH_MERGE_CASES}_STARTED`:
      analytics.trackView('Submit Merge');
      break;
    case `${ProcessInstanceActions.INVOICE_SUBMITTED}_STARTED`:
      analytics.trackView('Submit Case');
      break;
    case `${Bench.TOGGLE_CHARGEABLE_CASE}_STARTED`:
      analytics.trackView('Exclude Case');
      break;
    case `${Bench.HOLD_CASE}_STARTED`:
      analytics.trackView('Submit Hold');
      break;
    case Bench.SHOW_SPLIT_CASE_MODAL:
      analytics.trackView('Open Split Window');
      break;
    case `${MergeCases.SPLIT_CASE}_STARTED`:
      analytics.trackView('Submit Split');
      break;
    case `${Launch.NAVIGATE_PATIENT}_STARTED`:
      analytics.trackView('Open Timeline');
      break;
    case `${Launch.NAVIGATE_PROVIDER}_STARTED`:
      analytics.trackView('Open Provider Detail');
      break;
    case `${Launch.NAVIGATE_CODER_COMMENTS}_STARTED`:
      analytics.trackView('Open Coder Comments');
      break;
    //(manager only)
    case `${Bench.CANCEL_INVOICES_CONFIRM}_STARTED`:
      analytics.trackView('Void Case');
      break;
    //These should be sent as actions not views
    case `${Bench.CODE_SEARCH}_STARTED`:
      analytics.trackAction({ name: 'Code search' });
      break;
    default:
      // handle all other actions
      // in the mixpanel implementation, this is where the event is sent
      // in the datadog implementation, this is a no-op
      analytics.handleAction(action);
      break;
  }

  return next(action);
};
const analyticsMiddleware = middleWareWrapper(analyticsProcessor);

/**
 * An exposed way to track location changes.
 * Since the analytics factories are instantiated here,
 * this make a logical point to expose the functionality.
 * @param location
 */
export const handleLocationChange = (location: any) => {
  analytics.handleLocationChange(location);
};

export default analyticsMiddleware;
