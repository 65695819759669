import { isCQAWorkItemEvaluated } from './CQAWorkItemEvaluated';

const submitButtonConfig = {
  enabled: true,
  text: 'Save',
  tooltip: 'Submit your work and move to next task'
};

const addApprovalButtonConfig = {
  enabled: false,
  text: 'Add Approval',
  tooltip: 'Approve the workitem'
};

const approvedButtonConfig = {
  enabled: false,
  text: 'Approved',
  tooltip: 'Approved',
  classNames: ['success-button'],
  icon: 'BiomeCheckSVG20'
};

enum CQAStatus {
  IN_CQA = 'IN_CQA',
  CQA_COMPLETE = 'CQA_COMPLETE',
  CQA_APPROVED = 'CQA_APPROVED'
}

export function getUserRolesFromActiveContext(data: CMx.Context) {
  if (!data || !data.activeContext || !data.user || !data.user.roles) {
    return [];
  }

  const tenantId = data.activeContext.tenantId;

  // Find the matching roles object in user.roles array
  const matchingRole = data.user.roles.find(role => role.tenantId === tenantId);

  // Extract role names into an array
  return matchingRole ? matchingRole.roles.map(r => r.roleName) : [];
}

export const getSubmitButtonConfig = (
  gradeBenchPanelState: Accelerate.GradeBenchPanelState,
  roles: string[]
) => {
  const isEvaluated = isCQAWorkItemEvaluated(gradeBenchPanelState);

  if (
    gradeBenchPanelState.invoices.length > 0 &&
    gradeBenchPanelState.invoices[0].status === CQAStatus.IN_CQA
  ) {
    return { ...submitButtonConfig, enabled: isEvaluated };
  }
  if (
    gradeBenchPanelState.invoices.length > 0 &&
    gradeBenchPanelState.invoices[0].status === CQAStatus.CQA_COMPLETE
  ) {
    return {
      ...addApprovalButtonConfig,
      enabled: roles.includes('cqa_manager') && isEvaluated
    };
  }
  if (
    gradeBenchPanelState.invoices.length > 0 &&
    gradeBenchPanelState.invoices[0].status === CQAStatus.CQA_APPROVED
  ) {
    return approvedButtonConfig;
  }
  return submitButtonConfig;
};
