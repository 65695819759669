import React, { useEffect } from 'react';
import { Button } from '../../components/button/button';
import { ButtonSize } from '../../core/enums';
import theme from '../../theme';
import { Flex, Text } from '@chakra-ui/react';
import { styles } from './sso-login-styles';
import LoadingOverlay from '../../components/loader-overlay/loader-overlay';

const classes = styles();

type SSOLoginProps = {
  loading: boolean;
  match: any;
  loginViaSSO: (loginData: any) => void;
  exchangeTokenSso: (token: string) => void;
};

const SSOLogin: React.FC<SSOLoginProps> = (props: SSOLoginProps) => {
  const { loading, match, loginViaSSO, exchangeTokenSso } = props;

  const hash = window.location.hash.substr(1);
  let idToken = '';
  const searchParams = new URLSearchParams(hash);

  idToken = searchParams.get('id_token') ?? '';

  const orgCode = match?.params?.orgCode?.toUpperCase();

  const handleLogin = () => {
    loginViaSSO({ orgCode });
  };

  useEffect(() => {
    if (idToken.length > 0) {
      exchangeTokenSso(idToken);
    }
  }, [idToken, exchangeTokenSso]);

  return (
    <>
      {loading ? (
        <div className="centered-application">
          <div className="centered-application-wrapper">
            <LoadingOverlay loadingMsg="Logging you in..." />
          </div>
        </div>
      ) : (
        <Flex className="sso-form" sx={classes.ssoForm}>
          <Text sx={classes.ssoFormText}>
            Please click the login button below to authenticate with your
            organization. Once authenticated, you will be redirected back to
            CodaMetrix and automatically logged into the application.
          </Text>
          <Flex className="form-row" sx={classes.btnHeader}>
            <Button
              className="qa-sso-button"
              size={ButtonSize.MD}
              label={'Login'}
              backgroundColor={theme.colors.accent[100]}
              color={theme.colors.text['light']}
              disabled={loading}
              name="SSO Login"
              dataTestId="sso_login"
              onClick={handleLogin}
            />
          </Flex>
        </Flex>
      )}
    </>
  );
};

SSOLogin.displayName = 'SSOLogin';
export default SSOLogin;
